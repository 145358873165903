﻿/* global tumarket */

import { showDialog, closeDialog, showModal } from "./global/dialog";
import { getUrlParameter } from "./global/url";
import { getCookie, setCookie } from "./global/cookie";
import { addEventDelegate, appendHtml, toggleClass } from "./global/dom";
import { getSessionLength } from "./global/sessionTimer";
import { isIos } from "./global/util";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";

let tumPwa = {
	prompt: null,
	firstEvent: true,
	isPwa: false,
	options: {
		isEmployee: false
	},
	init: function (options) {
		Object.assign(this.options, options);

		if ("serviceWorker" in navigator) {
			if (navigator.serviceWorker.controller) {
				console.log("[PWA Builder] active service worker found, no need to register");
			} else {
				// Register the service worker
				navigator.serviceWorker
					.register("/pwabuilder-sw.js", {
						scope: "/"
					})
					.then(function (reg) {
						console.log("[PWA Builder] Service worker has been registered for scope: " + reg.scope);
					});
			}
		}

		if (window.matchMedia('(display-mode: standalone)').matches) {
			this.isPwa = true;
		}

		if (this.isPwa) {
			toggleClass(".tum-installPwaHowTo", "d-none", true);
			toggleClass(".tum-pwaOpenInBrowser", "d-none", false);
		}

		if ('getInstalledRelatedApps' in navigator) {
			navigator.getInstalledRelatedApps().then(function (e) {
				if (e.length) {
					toggleClass(".tum-installPwaHowTo", "d-none", true);

					if (getUrlParameter("installpwa") == "") {
						let isDev = location.href.indexOf("localhost") > -1 || location.href.indexOf("dev.") > -1;
						let title = '<span class="d-flex align-items-center" style="line-height:1.2em;"><img src="/Content/images/tu.m/logo/' + (isDev ? "dev" : "prod") + '/logo.png" alt="logo" class="d-inline mr-3">Приложение TU.Market</span>';
						let body = 'Приложение уже установлено на вашем устройстве.';
						showDialog({title: title, content: body, onShown: () => document.querySelector('#asModal .btn-primary').remove()});
					}
				}
			}).catch(error => {
				console.log(error);
			});
		}

		this.initEvents();
		this.showIosPwaPrompt();
	},
	initEvents: function(){
		window.addEventListener('beforeinstallprompt', e => {
			e.preventDefault();
			this.prompt = e;

			if (!this.isPwa) {
				toggleClass(".tum-installPwa", "d-none", false);
				toggleClass(".tum-installPwaHowTo", "d-none", true);

				if (getUrlParameter("installpwa") == "" && window.screen.width < 993) {
					this.showInstallPwaModal();
					return;
				}

				if (
					getCookie("skipPwaInstall") != "1" 
					&& this.firstEvent 
					&& window.screen.width < 993
					&& getSessionLength()/60 > 3
				) {
					this.showInstallPwaModal();
				}
			}

			this.firstEvent = false;
		});

		addEventDelegate(document, 'click', ".tum-installPwa", () => {
			this.install();
		});

		addEventDelegate(document, 'click', ".tum-pwa-scrollDown", () => {
			setCookie("skipPwaInstall", "1", 30);
			closeDialog();

			window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
		});

		addEventDelegate(document, "click", "[data-pwa-agree]", () => {
			closeDialog("#pwa-modal");
			this.install();

			import("./statistics").then(({ tumStats }) => {
				tumStats.logEvent({
					objectType: "pwa",
					eventType: "installPwa"
				});
			});
		});

		addEventDelegate(document, "click", "[data-pwa-cancel]", () => {
			setCookie("skipPwaInstall", "1", 30);
		});
	},
	showIosPwaPrompt: function(){
		if (!isIos()) return;
		if (this.isPwa) return;

		if (getUrlParameter("installpwa") == "" && window.screen.width < 993) {
			this.showInstallPwaModal();
			return;
		}

		if (
			getCookie("skipPwaInstall") != "1" 
			&& this.firstEvent 
			&& window.screen.width < 993
			&& getSessionLength()/60 > 3
		) {
			this.showInstallPwaModal();
		}

		this.firstEvent = false;
	},
	install: function () {
		this.prompt.prompt();

		this.prompt.userChoice.then(function (result) {
			if (result.outcome === 'accepted') {
				toggleClass(".tum-installPwa", "d-none", true);
			}
		});
	},
	showInstallPwaModal: function () {
		if (tumarket.disablePopups) return;
		var isDev = location.href.indexOf("localhost") > -1 || location.href.indexOf("dev.") > -1;

		loadPrecompiledTemplates(['pwa-modal']).then(() => {
			if (!document.getElementById('pwa-modal')) {
				let template = getPrecompiledTemplate('pwa-modal');
				let html = template({
					isDev, 
					ios: isIos()
				});

				appendHtml(document.body, html);
			}

			showModal('#pwa-modal');
		});
	}
};

window.tumPwa = tumPwa;

export default tumPwa;